import React, { useState } from "react";
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import Card from '../components/card';
import Button from '../components/button';
import AmountWrapper from '../components/amount-wrapper';
import { getLegacyImage } from '../common/utils';
import {
  getCart,
  getTotalPrice,
  getShippingCost,
  removeFromCart,
  updateProductQty,
} from '../common/cart';

const ResponsiveTD = styled.td`
  @media (max-width: 600px) {
    display: none;
  }
`;

export default () => {
  const [cart, setCart] = useState(getCart());

  function onQtyChange(e, product) {
    const target = e.target;
    let tr = target.parentNode;
    while (tr.nodeName !== 'TR') tr = tr.parentNode;
    const qtyElms = Array.from(tr.querySelectorAll(`[name=${product.slug}]`));
    qtyElms.find(elm => elm !== target).value = target.value;
    const qty = Number(e.target.value);
    updateProductQty(product, qty);
    setCart(getCart());
  }

  function removeProductFromCart(e, product) {
    e.preventDefault();
    removeFromCart(product);
    setCart(getCart());
  }

  function onCheckout(e) {
    e.target.textContent = 'Checking out...';
    e.target.disabled = true;
    window.location.href = '/checkout';
  }

  return (
    <Layout title="Your Shopping Cart | PasteWax">
      <section>
        <h1>My cart</h1>

        <div css={css`position: relative;`}>
          <Card css={css`
            width: calc(100% - 430px);

            @media (max-width: 960px) {
              width: 100%;
            }
          `}>
            <table css={css`
              width: 100%;

              th, td {
                border-bottom: 1px solid #eee;
                padding: 1rem 1.2rem;
              }

              th {
                color: #888;

                &.qty {
                  text-align: center;
                  width: 120px;
                }

                &.total {
                  text-align: right;
                  width: 100px;
                }
              }

              tr:last-child td {
                border-bottom: none;
              }

              td {
                &.qty {
                  text-align: center;
                }

                &.total {
                  text-align: right;
                }
              }
            `}>
              <thead css={css`
                @media (max-width: 600px) {
                  display: none;
                }

                th {
                  font-weight: 500;
                  text-align: left;
                }
              `}>
                <tr>
                  <th>Product</th>
                  <th className="qty">Quantity</th>
                  <th className="total">Total</th>
                </tr>
              </thead>
              <tbody>

                <tr className={cart.length ? 'hide' : ''}>
                  <td colSpan="3" align="center">No item added in your cart</td>
                </tr>

              {cart.map(cartItem => (
                <tr key={cartItem.slug + cartItem.color}>
                  <td>
                    <div css={css`
                      display: flex;
                    `}>
                      <picture>
                        <source srcSet={`/static/images/${cartItem.slug}/thumb-${cartItem.img}`}></source>
                        <img src={`/static/images/${cartItem.slug}/thumb-${getLegacyImage(cartItem.img)}`} alt={cartItem.title} width="72" />
                      </picture>

                      <div css={css`
                        margin-left: 1rem;
                      `}>
                        <a href={`/${cartItem.slug}`}>{cartItem.title}</a>
                        {cartItem.color ? ` - ${cartItem.color}` : ''}
                        <div css={css`
                          margin-top: 4px;
                        `}>PKR {cartItem.price}</div>
                      </div>
                    </div>

                    <div css={css`
                      display: none;
                      margin: 4px 0 0 88px;

                      @media (max-width: 600px) {
                        display: block;
                      }
                    `}>
                      <input type="number" name={cartItem.slug} defaultValue={cartItem.qty} min="1"
                        onChange={e => onQtyChange(e, cartItem)}
                        css={css`
                          padding: 0.5rem;
                          width: 45px;
                        `}
                      />
                      <a href="#" onClick={e => removeProductFromCart(e, cartItem)} css={css`
                        font-size: 0.9rem;
                        margin-left: 0.5rem;
                      `}>Remove</a>
                    </div>
                  </td>

                  <ResponsiveTD className="qty">
                    <input type="number" name={cartItem.slug} defaultValue={cartItem.qty} min="1"
                      onChange={e => onQtyChange(e, cartItem)}
                      css={css`
                        padding: 0.5rem;
                        width: 45px;
                      `}
                    />
                    <br />
                    <a href="#" onClick={e => removeProductFromCart(e, cartItem)} css={css`
                      font-size: 0.9rem;
                    `}>Remove</a>
                  </ResponsiveTD>

                  <ResponsiveTD className="total">PKR {cartItem.qty * cartItem.price}</ResponsiveTD>

                </tr>
              ))}

              </tbody>
            </table>
          </Card>

          <div css={css`
            box-sizing: border-box;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 400px;

            @media (max-width: 960px) {
              position: static;
              margin-top: 1.5rem;
              width: 100%
            }
          `}>
            <Card className="layout" css={css`
              position: sticky;
              top: 105px;
            `}>
              <AmountWrapper css={css`padding-top: 0;`}>
                <span>Subtotal:</span>
                <span>PKR {getTotalPrice()}</span>
              </AmountWrapper>

              <AmountWrapper>
                <span>Shipping:</span>
                {/* <span>PKR {cart.length ? 300 : 0}</span> */}
                <span>Free</span>
              </AmountWrapper>

              <AmountWrapper css={css`
                border-top: 1px solid #eee;
                font-size: 1rem;
                font-weight: 500;
                margin-top: 1rem;
                padding: 1rem 0;
              `}>
                <span>Total:</span>
                <span>PKR {getTotalPrice() + getShippingCost(cart)}</span>
              </AmountWrapper>

              <Button onClick={onCheckout} disabled={cart.length === 0}
                css={css`
                  background: #fc0;
                  border: none;
                  cursor: pointer;
                  font-family: "Open Sans", sans-serif;
                  font-size: 0.9rem;
                  font-weight: 600;
                  color: #666;
                  padding: 15px;
                  width: 100%;
                `}
              >
                Checkout
              </Button>
            </Card>
          </div>
        </div>

      </section>
    </Layout>
  );
};
