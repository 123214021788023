import styled from '@emotion/styled';

export default styled.button`
  background: #fc0;
  border: none;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: #666;
  padding: 15px;
  width: 100%;

  :disabled {
    cursor: default;
    opacity: 0.6;
  }
`;
